import { ThemeProvider, StyleReset, Div, Container, Row, Col, Text, Button, Anchor, currentDevice } from "atomize"
import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect } from "react"
import Footer from "../components/footer"
import "../settings/index.css"
import Header from "../components/header"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import SEO from "../components/seo"

import CoverWave from "../images/assets/w-come-funziona.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import BoldText from "../components/BoldText"
import { faExclamation, faMobileAlt, faShoePrints, faShoppingBasket } from "@fortawesome/free-solid-svg-icons"
import SubheaderText from "../components/SubheaderText"
import HeadingText from "../components/HeadingText"
import { Link } from "gatsby"

let config = require("../settings/config")

const theme = config.theme
const url = config.url

const HowStep = props => {
    return (
        <Col size={{ xs: "12", md: "4" }}>
            <Div m={{ b: { xs: "3rem", md: "0" } }}>
                <Div textAlign="center">{props.icon}</Div>
                <SubheaderText textColor={props.textColor} textAlign="center" p={{ t: "0.75rem", x: "1rem" }}>
                    {props.text}
                </SubheaderText>
            </Div>
        </Col>
    )
}

const FoodImage = props => {
    return (
        <Div pos="relative">
            {props.img}
            <AnchorLink to={url.orderNow} title="Ecco come ordinare! | Squiseat">
                <Div w="100%" d="flex" justify="center">
                    <Button
                        data-sal="zoom-in"
                        data-sal-delay="100"
                        pos="absolute"
                        h={{ xs: "3rem", lg: "4rem" }}
                        bottom={{ xs: "25%", lg: "45%" }}
                        p={{ x: "2rem" }}
                        bg="white"
                        shadow="3"
                        zIndex="2"
                        rounded="circle"
                    >
                        <Text textColor="primary" textSize={{ xs: "subheader", md: "title" }}>
                            {props.text}
                        </Text>
                    </Button>
                </Div>
            </AnchorLink>
        </Div>
    )
}

const How = () => {
    return (
        <ThemeProvider theme={theme}>
            <StyleReset />

            <Header />
            <SEO
                title="Scopri il nostro servizio antispreco. Ordina ora!"
                description="Scopri il nostro servizio di gastronomia ecosostenibile: scarica la nostra app e acquista le eccedenze alimentari a metà prezzo dai ristoranti partner!"
                pathname="come-funziona"
            />
            <Div tag="main" pos="relative" overflow="hidden">
                <Div
                    tag="section"
                    pos="relative"
                    bg="accent_ulight"
                    zIndex="1"
                    p={{
                        t: {
                            xs: "1rem",
                            sm: "2rem",
                            lg: "8rem",
                            xl: "10rem",
                        },
                    }}
                >
                    <Container>
                        <Text
                            textSize={{
                                xs: "display2",
                                md: "display3",
                            }}
                            textWeight="bold"
                            textAlign={{ lg: "center" }}
                            textColor="gunmetal"
                            m={{ t: { xs: "1rem", lg: "0.5rem" }, b: { xs: "2rem", lg: "0" } }}
                        >
                            <BoldText textColor="primary">Scegli</BoldText> le rimanenze e{" "}
                            <BoldText textColor="primary">paga la metà</BoldText>, senza sorprese!
                        </Text>
                        <SubheaderText
                            textAlign={{ lg: "center" }}
                            m={{ t: { md: "3rem" }, y: { xs: "1rem", md: "0" } }}
                        >
                            Mangi <BoldText>ciò che ti piace</BoldText>, <BoldText>paghi meno</BoldText> e{" "}
                            <BoldText>zero sprechi</BoldText>, figo no?
                        </SubheaderText>
                    </Container>
                </Div>

                <Div tag="section">
                    <Div m={{ t: { xs: "-1rem", lg: "-7rem" } }}>
                        <CoverWave className="wave" />
                    </Div>
                    <Container pos="relative" zIndex="1">
                        <Row m={{ y: { xs: "1.5rem", md: "0" } }} align="center">
                            <Col size={{ xs: "12", md: "7" }} style={{ padding: "0" }}>
                                <HeadingText p={{ y: "0.5rem" }}>
                                    <BoldText textColor="primary">Quello</BoldText> che vuoi,{" "}
                                    <BoldText textColor="primary">quanto</BoldText> ne vuoi.
                                </HeadingText>
                                <SubheaderText p={{ t: { xs: "1rem", md: "2rem" } }}>
                                    Compra <BoldText>ciò che ti serve</BoldText> selezionando le porzioni,{" "}
                                    <BoldText>nessun importo minimo</BoldText>, così non rischi di esagerare e
                                    strafogarti (o buttare via il cibo!)!
                                </SubheaderText>
                                <SubheaderText p={{ t: { xs: "0.5rem", md: "1.5rem" } }}>
                                    Ok la scelta, ma per alcuni locali come forni e pasticcerie{" "}
                                    <BoldText>è difficile essere</BoldText> super <BoldText>precisi</BoldText> e ti
                                    capiterà di trovare alcuni prodotti misti nei menu.
                                </SubheaderText>
                                <SubheaderText>
                                    Ma niente panico: la categoria la scegli tu, se volevi delle brioche troverai
                                    quelle! 😉
                                </SubheaderText>
                            </Col>
                            <Col
                                size={{ xs: "12", md: "5" }}
                                textSize={{ xs: "heading", md: "display2", lg: "display3" }}
                                textAlign="right"
                            >
                                <Div m={{ l: { xs: "52vw", md: "5vw", lg: "10vw" } }} pos="relative" zIndex="2">
                                    <Div
                                        w={{ xs: "9rem", md: "15rem", lg: "20rem" }}
                                        left={{ xs: "0", lg: "unset" }}
                                        m={{ x: { xs: "auto", lg: "unset" } }}
                                        pos="absolute"
                                        transform="rotate(35deg)"
                                    >
                                        <StaticImage
                                            src="../images/app-squiseat-sezione-prodotti-porzioni.png"
                                            alt="Logo di Squiseat negativo e senza testo"
                                            placeholder="blurred"
                                            className="logo"
                                        />
                                    </Div>
                                    <FontAwesomeIcon icon={faExclamation} size="10x" color="transparent" />
                                </Div>
                            </Col>
                        </Row>
                    </Container>
                </Div>

                <Div tag="section" m={{ y: "2rem" }}>
                    <Container>
                        <Row m={{ y: { xs: "3rem", md: "8rem" } }} align="center">
                            <Col
                                textSize={{ xs: "heading", md: "display2", lg: "display3" }}
                                size={{ xs: "12", md: "5" }}
                                textAlign="right"
                                order={{ xs: 2, md: 1 }}
                            >
                                <Div m={{ l: { xs: "52vw", md: "1vw", lg: "-2vw" } }} pos="relative" zIndex="2">
                                    <Div
                                        w={{ xs: "9rem", md: "15rem", lg: "20rem" }}
                                        left={{ xs: "0", lg: "unset" }}
                                        m={{ x: { xs: "auto", lg: "unset" } }}
                                        pos="absolute"
                                        transform={{ xs: "rotate(35deg)", lg: "rotate(25deg)" }}
                                    >
                                        <StaticImage
                                            src="../images/app-squiseat-sezione-checkout.png"
                                            alt="Logo di Squiseat negativo e senza testo"
                                            placeholder="blurred"
                                            className="logo"
                                        />
                                    </Div>
                                    <FontAwesomeIcon icon={faExclamation} size="10x" color="transparent" />
                                </Div>
                            </Col>
                            <Col size={{ xs: "12", md: "7" }} order={{ xs: 1, md: 2 }} style={{ padding: "0" }}>
                                <HeadingText p={{ y: "0.5rem" }}>
                                    <BoldText textColor="primary">Scegli</BoldText> quando ritirare
                                </HeadingText>
                                <SubheaderText p={{ t: { xs: "1rem", md: "2rem" } }}>
                                    Diversi locali hanno <BoldText>fasce di ritiro</BoldText> larghe o multiple,{" "}
                                    <BoldText>più comode</BoldText> per te.
                                </SubheaderText>
                                <SubheaderText p={{ t: { xs: "1rem", md: "2rem" } }}>
                                    Hai delle <BoldText>necessità</BoldText> particolari? Scrivi una nota al locale
                                    (sempre con educazione, mi raccomando) tipo "Non mi piacciono le acciughe, potreste
                                    darmi della pizza senza? Grazie!" 🍕
                                </SubheaderText>
                                <SubheaderText p={{ t: { xs: "1rem", md: "2rem" } }}>
                                    Se è rimasta solo con le acciughe è sfiga, altrimenti potreste essere accontentati
                                    volentieri!
                                </SubheaderText>
                            </Col>
                        </Row>
                    </Container>
                </Div>

                <Div tag="section" m={{ y: "2rem" }}>
                    <Container>
                        <Row m={{ y: { xs: "3rem", md: "8rem" } }} align="center">
                            <Col size={{ xs: "12", md: "7" }} style={{ padding: "0" }}>
                                <HeadingText p={{ y: "0.5rem" }}>
                                    <BoldText textColor="primary">Fatto</BoldText>! Ritira il tuo ordine
                                </HeadingText>
                                <SubheaderText p={{ t: { xs: "1rem", md: "2rem" } }}>
                                    Ora in sella a motorino, bici, monopattino, tappeto volante e{" "}
                                    <BoldText>ritira</BoldText> il tuo ordine.
                                </SubheaderText>
                                <SubheaderText>
                                    <BoldText>BUON APPETITO</BoldText>!
                                </SubheaderText>
                                <SubheaderText p={{ t: { xs: "1rem", md: "2rem" } }}>
                                    <BoldText>Hai un amico?</BoldText> <BoldText textColor="primary">Invitalo</BoldText>
                                    , ci saranno <BoldText>vantaggi</BoldText> per te, per lui e tutti gli amici che
                                    inviterai!
                                </SubheaderText>
                            </Col>
                            <Col
                                size={{ xs: "12", md: "5" }}
                                textSize={{ xs: "heading", md: "display2", lg: "display3" }}
                                textAlign="right"
                            >
                                <Div m={{ l: { xs: "52vw", md: "5vw", lg: "10vw" } }} pos="relative" zIndex="2">
                                    <Div
                                        w={{ xs: "9rem", md: "15rem", lg: "20rem" }}
                                        left={{ xs: "0", lg: "unset" }}
                                        m={{ x: { xs: "auto", lg: "unset" } }}
                                        pos="absolute"
                                        transform="rotate(35deg)"
                                    >
                                        <StaticImage
                                            src="../images/app-squiseat-sezione-ordini.png"
                                            alt="Logo di Squiseat negativo e senza testo"
                                            placeholder="blurred"
                                            className="logo"
                                        />
                                    </Div>
                                    <FontAwesomeIcon icon={faExclamation} size="10x" color="transparent" />
                                </Div>
                            </Col>
                        </Row>
                    </Container>
                </Div>

                <Div tag="section" bg="white" m={{ t: "10rem" }}>
                    <Container>
                        <HeadingText textAlign={{ lg: "center" }}>
                            Chi <BoldText textColor="primary">collabora</BoldText> con noi
                        </HeadingText>
                        <SubheaderText p={{ t: { xs: "1rem", md: "2rem" } }}>
                            Sì, lo sappiamo, abbiamo negozi <BoldText>principalmente a Bologna</BoldText> e provincia
                            per ora, ma <BoldText textColor="primary">stiamo arrivando</BoldText>!
                        </SubheaderText>
                        <Div m={{ y: "1rem" }}>
                            <iframe
                                src="https://www.google.com/maps/d/u/0/embed?mid=1IragiQRqGyqQ8n-4Nkvoj5E40Hjhvw-m"
                                title="business_map"
                                style={{
                                    border: "none",
                                    borderRadius: "10px",
                                }}
                                width="100%"
                                height="500px"
                            ></iframe>
                        </Div>
                    </Container>
                </Div>

                <Div tag="section">
                    <Div bg="white" pos="relative" m={{ y: "3rem" }} overflow="hidden">
                        <Container id="ordina_ora">
                            <Text tag="h1" d="none">
                                Che aspetti? Ordina ora con la nostra app antispreco!
                            </Text>
                            <Text tag="h2" d="none">
                                ACQUISTA LE RIMANENZE A METÀ DEL LORO PREZZO!
                            </Text>
                            <Text tag="h2" d="none">
                                Ecco una mappa dei locali che collaborano con Squiseat
                            </Text>
                            <HeadingText textAlign={{ lg: "center" }}>
                                Che aspetti? <BoldText textColor="primary">ORDINA ORA!</BoldText>
                            </HeadingText>
                            <Div m={{ t: "2rem", x: " 0" }}>
                                <SubheaderText textAlign="center">
                                    Se non ti abbiamo ancora convinto, <BoldText>fatti un giro sull’app</BoldText> e
                                    lasciati ispirare!
                                </SubheaderText>

                                <Div d={{ md: "flex" }} justify="center">
                                    <Anchor
                                        href="https://squiseat-subdomain.onelink.me/0GIO/WebsiteIOS"
                                        id="download_ios_come_funziona"
                                        target="_blank"
                                        title="Scarica per iPhone | Squiseat"
                                    >
                                        <Div
                                            w={{
                                                xs: "12.5rem",
                                                md: "15rem",
                                            }}
                                            m="1rem auto"
                                            p={{ x: "0.5rem" }}
                                        >
                                            <StaticImage
                                                src="../images/pulsante-download-app-store-ios.png"
                                                alt="Pulsante per il download su App Store iOS"
                                                placeholder="blurred"
                                            />
                                        </Div>
                                    </Anchor>

                                    <Anchor
                                        href="https://squiseat-subdomain.onelink.me/0GIO/WebsiteAndroid"
                                        id="download_android_come_funziona"
                                        target="_blank"
                                        title="Scarica per smartphone Android | Squiseat"
                                    >
                                        <Div
                                            w={{
                                                xs: "12.5rem",
                                                md: "15rem",
                                            }}
                                            m="1rem auto"
                                            p={{ x: "0.5rem" }}
                                        >
                                            <StaticImage
                                                src="../images/pulsante-download-play-store-android.png"
                                                alt="Pulsante per il download su Play Store Android"
                                                placeholder="blurred"
                                            />
                                        </Div>
                                    </Anchor>
                                </Div>
                            </Div>
                        </Container>
                    </Div>
                </Div>
            </Div>
            <Footer />
        </ThemeProvider>
    )
}

export default How
